<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogScheduleBlock"
			transition="dialog-top-transition"
			persistent
			width="700"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-form
				ref="form"
				v-model="validForm"
				lazy-validation
			>
				<v-card>
                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
						Block Schedule 
					</v-card-title>

					<v-card-text style="margin-top: 20px;">

						<v-row>
							<v-col cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<v-menu
									ref="mnuDateBlocked"
									v-model="mnuDateBlocked"
                                    :rules=[validations.required]
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<label>Date to Block</label>
										<v-text-field
											v-model="dateBlockedFormatted"
											prepend-inner-icon="mdi-calendar"
											v-bind="attrs"
											@blur="dateBlocked = parseDate(dateBlockedFormatted)"
                                            readonly
											outlined
											single-line
											v-on="on"
                                            dense
										></v-text-field>
									</template>
									<v-date-picker
										v-model="dateBlocked"
										no-title
										@input="mnuDateBlocked = false"
                                        :min="today"
										color="primary lighten-1"
									></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
                    
                        <v-row>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <v-menu
                                    ref="menuTimeStart"
                                    v-model="menuTimeStart"
                                    :close-on-content-click="false"
                                    :nudge-right="10"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <label>Start Time</label>
                                        <v-text-field
                                            v-model="timeStartDisplay"
                                            :rules=[validations.required]
                                            prepend-inner-icon="mdi-clock-time-four-outline"
                                            :suffix="AMPMStart"
                                            outlined
                                            single-line
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="timeStart"
                                        full-width
                                        format="ampm"
                                        :max="timeEnd"
                                        @update:period="setAMPMStart"
                                        @input="setDisplayTimeStart"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <v-menu
                                    ref="menuTimeEnd"
                                    v-model="menuTimeEnd"
                                    :close-on-content-click="false"
                                    :nudge-right="10"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="300px"
                                    min-width="300px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <label>End Time</label>
                                        <v-text-field
                                            v-model="timeEndDisplay"
                                            :rules=[validations.required]
                                            prepend-inner-icon="mdi-clock-time-four-outline"
                                            :suffix="AMPMEnd"
                                            outlined
                                            single-line
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="timeEnd"
                                        full-width
                                        format="ampm"
                                        :min="timeStart"
                                        @update:period="setAMPMEnd"
                                        @input="setDisplayTimeEnd"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
						<v-row>
							<v-col cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<label>Description</label>
								<v-textarea
									v-model="scheduleBlockRequest.description"
									single-line
									rows="4"
									row-height="30"
									outlined
								>
								</v-textarea>
							</v-col>
						</v-row>

					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions 
                        class="justify-center"
                    >
							<ActionButtons
								:request="scheduleBlockRequest"
								:loading="loading"
								:showButtons="{
									cancel: true,
									save: true
								}"
								@cancel="cancel"
								@save="save"
							/>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

	export default {

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        props: {
            id: {
                default: 0
            },

			showDialogScheduleBlock: {
				default: false
			},
		},
		
        data: vm => ({

            validForm: true,
			loading: false,

			scheduleBlockRequest: {
                id: 0,
                idUser: "",
                dateBlocked: "",
                description: ""
            },
            menuTimeStart: false,
            menuTimeEnd: false,
            AMPMStart: "AM",
			timeStart: null,
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEnd: null,
			timeEndDisplay: null,
            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateBlocked: vm.today,
            dateBlockedFormatted: vm.formatDate(vm.today),
            mnuDateBlocked: false,

            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        watch: {
            dateBlocked () {
                this.dateBlockedFormatted = this.formatDate(this.dateBlocked)
            },
        },

        methods: {

            setAMPMStart(period){
                this.AMPMStart = period.toUpperCase();
            },
            
            setDisplayTimeStart(timeStart){
                var timeSplit = timeStart.split(':')

                if (timeSplit) {
                    let qtdeHour = 0;
                    if (this.AMPMStart == "PM" && parseInt(timeSplit[0]) != 12) {
                        qtdeHour = 12
                    }
                    if (this.AMPMStart == "AM" && parseInt(timeSplit[0]) == 0) {
                        qtdeHour = -12
                    }
                    this.timeStartDisplay = `${parseInt(timeSplit[0]) - qtdeHour}:${timeSplit[1]}`;
                }
            },

            setAMPMEnd(period){
                this.AMPMEnd = period.toUpperCase();
            },
            

            setDisplayTimeEnd(timeEnd){
                var timeSplit = timeEnd.split(':')

                if (timeSplit) {
                    let qtdeHour = 0;
                    if (this.AMPMEnd == "PM" && parseInt(timeSplit[0]) != 12) {
                        qtdeHour = 12
                    }
                    if (this.AMPMEnd == "AM" && parseInt(timeSplit[0]) == 12) {
                        qtdeHour = -12
                    }
                    this.timeEndDisplay = `${parseInt(timeSplit[0]) - qtdeHour}:${timeSplit[1]}`;
                }
            },

			async clearFields() {
				this.dateBlocked = this.today;
				this.dateBlockedStart = "";
				this.dateBlockedEnd = null;
				this.AMPMStart = "";
				this.AMPMEnd = "";
				this.timeStart = null;
				this.timeStartDisplay = null;
				this.timeEnd = null;
				this.timeEndDisplay = null;
				this.scheduleBlockRequest.description = "";
			},
			
            async cancel() {

                this.$emit('update:showDialogScheduleBlock', false);
				this.clearFields();
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
					this.loading = true;
					this.showLoading();

					this.scheduleBlockRequest.id = this.id;
					this.scheduleBlockRequest.idUser = this.userLoggedGetters.id;
					
					this.scheduleBlockRequest.dateBlockedStart = this.dateBlocked + " " + this.timeStart;
					this.scheduleBlockRequest.dateBlockedEnd = this.dateBlocked + " " + this.timeEnd;

					const result = await this.$store.dispatch("scheduleModule/DateBlockedCreateUpdate", this.scheduleBlockRequest);

					if (result.success === true) {
						this.showToast("success", "Success!", result.message);
						this.cancel();
					}
					else {
						this.showToast("error", "Warning!", result.message);
					}

					this.$emit('methodConfirmToCall');
					this.loading = false;
					this.hideLoading();
                }
            },
        }
    };
</script>