<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogSalesDetails"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
            <v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    <v-col 
                        cols="6"
                    >
                        Job Details
                    </v-col>
                    <v-col 
                        cols="6"
                        style="text-align: right;"
                    >
                        <v-chip
                            :color="getStatusColor(serviceRequest.status)"
                            outlined
                            dark
                            style="font-size: 14px !important;"
                        >
                            {{ serviceRequest.statusDescription }}
                        </v-chip>
                    </v-col>
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="padding-bottom: 15px;"
                        >
                            <label>Appointment Details</label>                                
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="padding-bottom: 15px;"
                        >
                            <label style="font-size: 20px !important; color: var(--color__main)"> {{ appointmentDate }} </label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="font-size: 15px;" v-bind:href="'https://maps.google.com/?q=' + googleMapsAddress" target="_blank">{{ googleMapsAddress }}</a>
                                </template>
                                <span>Go to maps</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="padding-bottom: 15px;"
                        >
                            &nbsp;
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Created By</label>
                            <v-text-field
                                v-model="serviceRequest.userNameRegister"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="3"
                            sm="12"
                        >
                            <label>Created On</label>
                            <v-text-field
                                v-model="serviceRequest.dateRegisterFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="3"
                            md="3"
                            sm="12"
                        >
                            <label>Expire On</label>
                            <v-text-field
                                v-model="serviceRequest.dateExpireFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Customer</label>
                            <v-text-field
                                v-model="serviceRequest.customerSelected.name"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Address</label>
                            <v-text-field
                                v-model="serviceRequest.addressSelected.address"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Estimated Buget</label>
                            <v-text-field
                                v-model="serviceRequest.estimatedBudgetFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Estimated Date</label>
                            <v-text-field
                                v-model="serviceRequest.estimatedDateFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Estimated Value - Zillow</label>
                            <br />
                            <label 
                                style="font-size: 30px !important;line-height: 45px; color: var(--color__main) !important"
                            >
                                {{ estimatedValueZillow }}
                            </label>
                            <zillowTerms :address="serviceRequest.addressSelected.address" :lgWidthTerms="'6'" :lgWidthLogo="'6'" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Phone</label>
                            <v-text-field
                                v-model="serviceRequest.phone"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Email</label>
                            <v-text-field
                                v-model="serviceRequest.email"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="serviceRequest.notes"
                                single-line
                                readonly
                                rows="2"
                                row-height="20"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions 
                    class="justify-center"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2 dialogButtonDismiss"
                                v-on="on"
                                @click="dismiss"
                            >
                                Dismiss
                            </v-btn>
                        </template>
                        <span>Dismiss</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="serviceRequest.status == 0"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                v-if="serviceRequest.status == 0"
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__red) !important; color: #ffffff !important"
                                @click="showDialogDeniedService = true"
                            >
                                Deny
                            </v-btn>
                        </template>
                        <span>Deny the service</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="serviceRequest.status == 0"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="serviceRequest.status == 0"
                                class="mx-2"
                                v-on="on"
                                :loading="loading"
                                @click="confirmDecision('ACCEPT')"
                            >
                                Accept
                            </v-btn>
                        </template>
                        <span>Accept the service</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="serviceRequest.status == 2"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="serviceRequest.status == 2"
                                class="mx-2"
                                v-on="on"
                                :loading="loading"
                                @click="confirmDecision('START')"
                            >
                                Start
                            </v-btn>
                        </template>
                        <span>Start the service</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<v-dialog
			v-model="showDialogDeniedService"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Explain your reason
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Reason</label>
                            <v-textarea
                                v-model="descriptionDenied"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
					

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogDeniedService = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmDecision('DENY')"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import zillowTerms from '@/components/Zillow/ZillowTerms';
    import ActionDialog from "@/components/Layout/ActionDialog";
    // import ContentHeader from "@/components/Layout/ContentHeader.vue";

	export default {

        components: {
            zillowTerms,
            ActionDialog,
            // ContentHeader
        },

        mixins: [Helpers],

        props: {

            id: {
                default: 0
            },

            idAux: {
                default: 0
            },

			showDialogSalesDetails: {
				default: false
			},
		},

        computed: {
            
            googleMapsAddress() {
                let googleMapsAddress = this.serviceRequest.appointmentAddress;

                if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                    
                    if (this.serviceRequest.appointmentCitySelected.stateSelected != null && this.serviceRequest.appointmentCitySelected.stateSelected != undefined) {
                        googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.stateSelected.code}`;
                    }

                    googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.description}`;
                }

                return googleMapsAddress;
            },

            appointmentDate() {

                return `${this.serviceRequest.startDateFormatted} - ${this.serviceRequest.timeStartFormatted} ${this.serviceRequest.ampmTimeStart} to ${this.serviceRequest.timeCloseFormatted} ${this.serviceRequest.ampmTimeEnd}`
            }
        },
		
        data: () => ({

            validForm: true,
			loading: false,

            tab: null,

            estimatedValueZillow: "$ 0,00",
            showDialogDeniedService: false,
            descriptionDenied: "",

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                appointmentCitySelected: {
                    description: "",
                    stateSelected: {
                        code: ""
                    }
                }
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
		}),

        watch: {

            async "idAux"() {
                await this.getRegister()
            },
        },

        methods: {
			
            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__status_pending)';
                        break;

                    case 4:
                        color = 'var(--color__completed)';
                        break;

                    case 5:
                        color = 'var(--color__completed)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },
            
            async dismiss() {
                this.$emit('update:showDialogSalesDetails', false);
            },

            async clearFields() {
                this.tab = 0;
                this.serviceRequest = {
                    userNameRegister: "",
                    customerSelected: {
                        name: ""
                    },
                    addressSelected: {
                        address: ""
                    }
                }
            },
            
            async getRegister() {

                await this.clearFields();

                if (this.id !== 0) {
                    let response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;

                    this.getEstimatedValueZillow();
                }
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.serviceRequest.isCustomer === 1) {
                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                    }
                }
                else {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.address;
                }

                let response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                let resultZillow = response.data.result;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }
            },
            
            confirmDecision(type) {

                /*
                    statusId:
                        1 - UNASSIGNED;
                        2 - ACCEPTED;
                        3 - IN PROGRESS;
                */
               
               let statusId = 0;
               switch (type) {
                case 'ACCEPT': statusId = 2; break;
                case 'DENY':   statusId = 1; break;
                case 'START':  statusId = 3; break;
               
                default:
                    break;
               }

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will ${type} this service, confirm your decision?`,
                    methodConfirm: this.confirm,
                    params: statusId,
                };
            },

            async confirm(idStatus) {

                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: idStatus,
                    description: this.descriptionDenied
                };

                let result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);

                    if (idStatus == 3) {
                        this.$router.push({ name: "scopeOfWork", params: { id: this.id } });
                    }
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.showDialogDeniedService = false;
                this.descriptionDenied = "";
                this.$emit('methodConfirmToCall');
                this.dismiss();

                this.hideLoading();
            },
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>