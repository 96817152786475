<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogScheduleMeeting"
			transition="dialog-top-transition"
			persistent
			width="1000"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
            <v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    Appointment
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-tabs
                        v-model="tab"
                        fixed-tabs
                    >
                        <v-tab key="1">
                            Appointment Details
                        </v-tab>
                        <v-tab key="2">
                            Internal Recipients
                        </v-tab>
                        <v-tab key="3">
                            External Recipients
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        
                        <!-- APPOINTMENT -->
                        <v-tab-item key="1">
                            <br />
                            <v-form
                                ref="form"
                                v-model="validForm"
                                lazy-validation
                            >
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-menu
                                            ref="mnuDateMeeting"
                                            v-model="mnuDateMeeting"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <label>Meeting Date</label>
                                                <v-text-field
                                                    v-model="dateMeetingFormatted"
                                                    prepend-inner-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    :rules=[validations.required]
                                                    @blur="dateMeeting = parseDate(dateMeetingFormatted)"
                                                    readonly
                                                    outlined
                                                    single-line
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="dateMeeting"
                                                no-title
                                                @input="mnuDateMeeting = false"
                                                :min="today"
                                                color="primary lighten-1"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-menu
                                            ref="menuTimeStart"
                                            v-model="menuTimeStart"
                                            :close-on-content-click="false"
                                            :nudge-right="10"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="300px"
                                            min-width="300px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <label>Start Time</label>
                                                <v-text-field
                                                    v-model="timeStartDisplay"
                                                    :rules=[validations.required]
                                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                                    :suffix="AMPMStart"
                                                    outlined
                                                    single-line
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-model="timeStart"
                                                full-width
                                                format="ampm"
                                                :max="timeEnd"
                                                @update:period="setAMPMStart"
                                                @input="setDisplayTimeStart"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-menu
                                            ref="menuTimeEnd"
                                            v-model="menuTimeEnd"
                                            :close-on-content-click="false"
                                            :nudge-right="10"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="300px"
                                            min-width="300px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <label>End Time</label>
                                                <v-text-field
                                                    v-model="timeEndDisplay"
                                                    :rules=[validations.required]
                                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                                    :suffix="AMPMEnd"
                                                    outlined
                                                    single-line
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-model="timeEnd"
                                                full-width
                                                format="ampm"
                                                :min="timeStart"
                                                @update:period="setAMPMEnd"
                                                @input="setDisplayTimeEnd"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Customer</label>
                                        <v-combobox
                                            v-model="scheduleMeetingRequest.customerSelected"
                                            :items="listCustomer"
                                            item-text="name"
                                            item-value="id"
                                            clearable
                                            single-line
                                            outlined
                                            @change="getServices()"
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Job</label>
                                        <v-combobox
                                            v-model="scheduleMeetingRequest.serviceSelected"
                                            :items="listService"
                                            :disabled="scheduleMeetingRequest.customerSelected == null || scheduleMeetingRequest.customerSelected == undefined"
                                            item-text="resumeDescription"
                                            item-value="id"
                                            clearable
                                            single-line
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Subject</label>
                                        <v-text-field
                                            v-model="scheduleMeetingRequest.subject"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Appointment Address</label>
                                        <v-text-field
                                            v-model="scheduleMeetingRequest.appointmentAddress"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col  
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="6"
                                    >
                                        <label>State</label>
                                        <v-combobox
                                            v-model="scheduleMeetingRequest.appointmentStateSelected"
                                            :items="listStateAppointmentAddress"
                                            :rules=[validations.required]
                                            item-text="codeDescription"
                                            item-value="id"
                                            clearable
                                            outlined
                                            @change="getListCityByStateAppointmentAddress()"
                                            dense
                                        ></v-combobox>
                                    </v-col>

                                    <v-col  
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="6"
                                    >
                                        <label>City</label>
                                        <v-combobox
                                            v-model="scheduleMeetingRequest.appointmentCitySelected"
                                            :items="listCityAppointmentAddress"
                                            :rules=[validations.required]
                                            item-text="codeStateDescriptionCity"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col  
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                            <a
                                                v-on="on"
                                                v-bind:href="'https://maps.google.com/?q=' + googleMapsAddress" 
                                                target="_blank"
                                            >
                                                <v-icon color="primary" left>mdi-map-search</v-icon>
                                                {{ googleMapsAddress }}
                                            </a>
                                            </template>
                                            <span>Click here to check address in Google Maps</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Description</label>
                                        <v-textarea
                                            v-model="scheduleMeetingRequest.description"
                                            single-line
                                            rows="4"
                                            row-height="30"
                                            outlined
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>

                        <!-- RECIPIENTS -->
                        <v-tab-item key="2">
                            <br />
                            <v-row>
                                <v-col 
                                    style="padding: 10px;"
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <h3>Internal Recipients</h3>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col 
                                    style="padding: 10px;"
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Profile</label>
                                    <v-combobox
                                        v-model="profileSelected"
                                        :items="listProfile"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        single-line
                                        outlined
                                        @change="filterUsers();"
                                        dense
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col  
                                    cols="5"
                                    lg="5"
                                    md="5"
                                    sm="5"
                                >
                                    <v-card
                                        class="mx-auto"
                                        max-width="300"
                                        tile
                                    >
                                        <v-list 
                                            dense
                                            style="max-height: 250px; min-height: 250px"
                                            class="overflow-y-auto"
                                        >
                                            <v-subheader>Users</v-subheader>
                                            <v-list-item-group
                                                color="primary"
                                            >
                                                <v-list-item
                                                    v-for="(itemUser, index) in listUser"
                                                    :key="index"
                                                    @dblclick="addRecipient(itemUser)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon v-text="'mdi-account'"></v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="itemUser.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col  
                                    style="display: grid; align-content: center; align-items: center;"
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                text
                                                style="background-color: white !important; color: black !important;"
                                                @click="addAllRecipients()"
                                            >
                                                <v-icon>
                                                    mdi-arrow-collapse-right
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add All Users</span>
                                    </v-tooltip>
                                    <br />
                                    <br />
                                    <br />
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                text
                                                style="background-color: white !important; color: black !important;"
                                                @click="removeAllRecipients()"
                                            >
                                                <v-icon>
                                                    mdi-arrow-collapse-left
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Remove All Users</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col  
                                    cols="5"
                                    lg="5"
                                    md="5"
                                    sm="5"
                                >
                                    <v-card
                                        class="mx-auto"
                                        max-width="300"
                                        tile
                                    >
                                        <v-list 
                                            dense
                                            style="max-height: 250px; min-height: 250px"
                                            class="overflow-y-auto"
                                        >
                                            <v-subheader>Recipients</v-subheader>
                                            <v-list-item-group
                                                v-model="recipientSelected"
                                                color="primary"
                                            >
                                                <v-list-item
                                                    v-for="(itemRecipient, index) in listRecipients"
                                                    :key="index"
                                                    @dblclick="removeRecipient(index)"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon>mdi mdi-account</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ itemRecipient.name }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- EXTERNAL RECIPIENTS -->
                        <v-tab-item key="3">
                            <br />
                            <v-row>
                                <v-col 
                                    style="padding: 10px;"
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <h3>External Recipients</h3>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col 
                                    style="padding: 10px;"
                                    cols="5"
                                    lg="5"
                                    md="5"
                                    sm="5"
                                >
                                        <label>Name</label>
                                        <v-text-field
                                            v-model="externalRecipientName"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            dense
                                        >
                                        </v-text-field>
                                </v-col>
                                <v-col 
                                    style="padding: 10px;"
                                    cols="5"
                                    lg="5"
                                    md="5"
                                    sm="5"
                                >
                                        <label>Email</label>
                                        <v-text-field
                                            v-model="externalRecipientEmail"
                                            :rules=[validations.required,validations.email]
                                            single-line
                                            outlined
                                            dense
                                        >
                                        </v-text-field>
                                </v-col>
                                <v-col
                                    style="display: grid; align-content: center; align-items: center;"
                                    cols="2"
                                    lg="2"
                                    md="2"
                                    sm="2"
                                >
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn 
                                                class="mx-2"
                                                v-on="on"
                                                @click="addExternalRecipient()"
                                            >
                                                Add
                                            </v-btn>
                                        </template>
                                        <span>Add External Recipient</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col  
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-data-table
                                        :headers="headersExternalRecipient"
                                        :items="listRecipientsExternal"
                                        no-data-text="No external recipients"
                                        hide-default-footer
                                    >
                                        <template v-slot:item.action="{item}">
                                            <ActionList 
                                                :id="item.id" 
                                                :showButtons="{
                                                    delete: true
                                                }"
                                                @confirmDelete="confirmRecipientExternalDelete" />
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions 
                    class="justify-center"
                >
                    <ActionButtons
                        :request="scheduleMeetingRequest"
                        :loading="loading"
                        :showButtons="{
                            cancel: true,
                            save: true
                        }"
                        @cancel="cancel"
                        @save="save"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionList from "@/components/Layout/ActionList";

	export default {

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionList
        },

        props: {

            id: {
                default: 0
            },

            idAux: {
                default: 0
            },

			showDialogScheduleMeeting: {
				default: false
			},
		},
		
        data: vm => ({

            validForm: true,
			loading: false,

            tab: null,

			scheduleMeetingRequest: {
                id: 0,
                dateMeeting: "",
                serviceSelected: null,
                customerSelected: null,
                subject: "",
                appointmentAddress: "",
                appointmentIDCity: "",
                description: ""
            },
            
            listCityAppointmentAddress: [],
            listStateAppointmentAddress: [],

            profileSelected: null,
            userSelected: null,
            recipientSelected: null,
            listRecipients: [],
            listRecipientsExternal: [],

            listProfile: [],
            listUser: [],

            menuTimeStart: false,
            menuTimeEnd: false,
            AMPMStart: "AM",
			timeStart: null,
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEnd: null,
			timeEndDisplay: null,
            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateMeeting: vm.today,
            dateMeetingFormatted: vm.formatDate(vm.today),
            mnuDateMeeting: false,

            externalRecipientName: "",
            externalRecipientEmail: "",

            listService: [],
            listCustomer: [],

            headersExternalRecipient: [
                { text: "Name", value: "name", sortable: true },
                { text: "Email", value: "email", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            validations: {
                required: required,
                email: validEmail
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            
            googleMapsAddress() {
                let googleMapsAddress = this.scheduleMeetingRequest.appointmentAddress;
                
                if (this.scheduleMeetingRequest.appointmentStateSelected != null && this.scheduleMeetingRequest.appointmentStateSelected != undefined) {
                    googleMapsAddress += `, ${this.scheduleMeetingRequest.appointmentStateSelected.code}`;
                }

                if (this.scheduleMeetingRequest.appointmentCitySelected != null && this.scheduleMeetingRequest.appointmentCitySelected != undefined) {
                    googleMapsAddress += `, ${this.scheduleMeetingRequest.appointmentCitySelected.descriptionCity ?? this.scheduleMeetingRequest.appointmentCitySelected.description}`;
                }

                return googleMapsAddress;
            }
        },

        watch: {

            async "idAux"() {
                await this.getRegister()
            },

            dateMeeting () {
                this.dateMeetingFormatted = this.formatDate(this.dateMeeting)
            },
        },

        methods: {

            setAMPMStart(period){
                this.AMPMStart = period.toUpperCase();
            },
            
            setDisplayTimeStart(timeStart){
                var timeSplit = timeStart.split(':')

                if (timeSplit) {
                    let qtdeHour = 0;
                    if (this.AMPMStart == "PM" && parseInt(timeSplit[0]) != 12) {
                        qtdeHour = 12
                    }
                    if (this.AMPMStart == "AM" && parseInt(timeSplit[0]) == 0) {
                        qtdeHour = -12
                    }
                    this.timeStartDisplay = `${parseInt(timeSplit[0]) - qtdeHour}:${timeSplit[1]}`;
                }
            },

            setAMPMEnd(period){
                this.AMPMEnd = period.toUpperCase();
            },            

            setDisplayTimeEnd(timeEnd){
                var timeSplit = timeEnd.split(':')

                if (timeSplit) {
                    let qtdeHour = 0;
                    if (this.AMPMEnd == "PM" && parseInt(timeSplit[0]) != 12) {
                        qtdeHour = 12
                    }
                    if (this.AMPMEnd == "AM" && parseInt(timeSplit[0]) == 12) {
                        qtdeHour = -12
                    }
                    this.timeEndDisplay = `${parseInt(timeSplit[0]) - qtdeHour}:${timeSplit[1]}`;
                }
            },

			async clearFields() {
				this.dateMeeting = this.today;
                this.dateMeetingFormatted = this.formatDate(this.today),
				this.dateMeetingStart = "";
				this.dateMeetingEnd = null;
				this.AMPMStart = "AM";
				this.AMPMEnd = "AM";
				this.timeStart = null;
				this.timeStartDisplay = null;
				this.timeEnd = null;
				this.timeEndDisplay = null;
				this.scheduleMeetingRequest.subject = "";
				this.scheduleMeetingRequest.local = "";
				this.scheduleMeetingRequest.description = "";
                this.scheduleMeetingRequest.appointmentAddress = "";
                this.scheduleMeetingRequest.appointmentStateSelected = null;
                this.scheduleMeetingRequest.appointmentCitySelected = null;
                this.externalRecipientName = " ",
                this.externalRecipientEmail = " ",
                this.listRecipients = [];
                this.listRecipientsExternal = [];
			},
			
            async cancel() {

                this.$emit('update:showDialogScheduleMeeting', false);
				this.clearFields();
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async filterUsers () {
                let scheduleFilterRequest = {
                    idProfile: 0,
                    idUser: 0
                }

                this.listUser = await this.$store.dispatch("userModule/List");
                if (this.profileSelected != null && this.profileSelected != undefined && this.profileSelected.id != 0) {
                    scheduleFilterRequest.idProfile = this.profileSelected.id
                    this.listUser = this.listUser.filter(usr => usr.idProfile == scheduleFilterRequest.idProfile)
                }

                await this.addFirstItemList();
                await this.checkListUser();
            },

            async addFirstItemList() {
                let itemAllProfiles = {
                    id: 0,
                    name: 'All Profiles'
                }

                this.listProfile.unshift(itemAllProfiles)

                if (this.profileSelected == null || this.profileSelected == undefined) {
                    this.profileSelected = itemAllProfiles;
                }
            },

            async checkListUser() {
                
                let listUserClone = [...this.listUser];

                this.listUser.forEach(itemUser => {
                    let existsUser = this.listRecipients.filter(rec => rec.idUser == itemUser.id)

                    if (existsUser != null && existsUser != undefined && existsUser.length > 0) {
                        let index = 0;
                        let foundUser = false;
                        listUserClone.forEach(itemUserClone => {
                            if (itemUserClone.id != itemUser.id && foundUser == false) {
                                index++;
                            }
                            else {
                                foundUser = true
                            }
                        })
                        listUserClone.splice(index, 1);
                    }
                })

                this.listUser = listUserClone;
            },

            async addRecipient(itemUser) {

                if (itemUser && itemUser != null && itemUser != undefined) {
                    let recipientAdded = this.listRecipients.filter (rec => rec.idUser == itemUser.id)
                    
                    if (recipientAdded == null || recipientAdded == undefined || recipientAdded.length == 0) {

                        this.listRecipients.push({
                            id: Math.random(),
                            name: itemUser.name,
                            idUser: itemUser.id,
                            newRegister: true
                        });
                    }
                }

                await this.checkListUser();
            },

            async addAllRecipients() {
                this.listUser.forEach (itemUser => {
                    this.addRecipient(itemUser);
                })
                await this.checkListUser();
            },

            async removeAllRecipients() {
                this.listRecipients = [];
                await this.filterUsers();
                await this.checkListUser();
            },

            async removeRecipient(index) {

                if (this.listRecipients.length > 0) {
                    this.listRecipients.splice(index, 1);
                    await this.filterUsers();
                }
            },

            async addExternalRecipient() {
                if (this.externalRecipientName == "" || this.externalRecipientName == null || this.externalRecipientEmail == "" || this.externalRecipientEmail == null ) {
                    this.showToast("error", "Warning!", "Name and Email is required!");
                    return false;
                }
                else {
                    let externalUserAdded = this.listRecipientsExternal.filter(rec => rec.email == this.externalRecipientEmail);

                    if (externalUserAdded != null && externalUserAdded != undefined && externalUserAdded.length > 0) {
                        this.showToast("error", "Warning!", "Email already added!");
                        return false;
                    }
                    else {
                        this.listRecipientsExternal.push({
                            id: Math.random(),
                            name: this.externalRecipientName.trim(),
                            email: this.externalRecipientEmail.trim(),
                            newRegister: true
                        })
                    }
                }
            },

            async confirmRecipientExternalDelete(id) {

                let index = 0
                this.listRecipientsExternal.forEach(itemRecipient => {
                    if (itemRecipient.id == id) {

                        this.listRecipientsExternal.splice(index, 1);
                    }
                    index++;
                });
            },

            async getListCityByStateAppointmentAddress() {
                
                this.scheduleMeetingRequest.appointmentCitySelected = null;

                let idState = 0;

                if (this.scheduleMeetingRequest.appointmentStateSelected != null && this.scheduleMeetingRequest.appointmentStateSelected != undefined) {
                    idState = this.scheduleMeetingRequest.appointmentStateSelected.id;
                }

                this.listCityAppointmentAddress = await this.$store.dispatch("cityModule/List", idState);
            },
            
            async getRegister() {

                await this.clearFields();
                await this.getLists();
                await this.filterUsers();
                this.tab = 0

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("scheduleModule/DateMeetingGetById", this.id);

                    if (response.success) {
                        this.scheduleMeetingRequest = response.result;
                        this.listRecipients = this.scheduleMeetingRequest.listRecipients;
                        this.listRecipientsExternal = this.scheduleMeetingRequest.listRecipientsExternal;

                        this.dateMeeting = this.parseDate(this.scheduleMeetingRequest.dateMeetingFormatted);
                        this.dateMeetingFormatted = this.scheduleMeetingRequest.dateMeetingFormatted;
                        this.timeStart = this.scheduleMeetingRequest.timeStartFormatted;
                        this.timeStartDisplay = this.scheduleMeetingRequest.timeStartFormatted;
                        this.AMPMStart = this.scheduleMeetingRequest.amPmTimeStart;
                        this.timeEnd = this.scheduleMeetingRequest.timeEndFormatted;
                        this.timeEndDisplay = this.scheduleMeetingRequest.timeEndFormatted;
                        this.AMPMEnd = this.scheduleMeetingRequest.amPmTimeEnd;
                        await this.checkListUser();
                        
                        let appointmentCitySelected = {...this.scheduleMeetingRequest.appointmentCitySelected};

                        if (appointmentCitySelected) {
                            this.scheduleMeetingRequest.appointmentStateSelected = appointmentCitySelected.stateSelected;
                            await this.getListCityByStateAppointmentAddress();
                            this.scheduleMeetingRequest.appointmentCitySelected = appointmentCitySelected;
                        }
                    }

                }
                else {
                    this.scheduleMeetingRequest.appointmentStateSelected = {
                        id: 13,
                        codeDescription: "FL - Florida"
                    }

                    this.getListCityByStateAppointmentAddress()
                }
            },

            async getLists() {
                
                this.listProfile = await this.$store.dispatch("profileModule/List");
                this.listUser = await this.$store.dispatch("userModule/List");
                this.listStateAppointmentAddress = await this.$store.dispatch("stateModule/List");
                this.listCustomer = await this.$store.dispatch("customerModule/ListByServiceUserLogged");
            },
            
            async getServices() {

                let idCustomer = 0;
                this.scheduleMeetingRequest.serviceSelected = null;

                if (this.scheduleMeetingRequest.customerSelected != null && this.scheduleMeetingRequest.customerSelected != undefined) {
                    idCustomer = this.scheduleMeetingRequest.customerSelected.id;
                }

                this.listService = await this.$store.dispatch("serviceModule/ListByCustomer", idCustomer);
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    if ((this.listRecipients == null || this.listRecipients == undefined || this.listRecipients.length == 0) &&
                        (this.listRecipientsExternal == null || this.listRecipientsExternal == undefined || this.listRecipientsExternal.length == 0)) {
                        this.showToast("error", "Warning!", "No recipients added");
                    }
                    else {
                    
                        this.loading = true;
                        this.showLoading();

                        this.scheduleMeetingRequest.id = this.id;
                        this.scheduleMeetingRequest.idService = 0;
                        this.scheduleMeetingRequest.idCustomer = 0;
                        
                        if (this.scheduleMeetingRequest.appointmentCitySelected != null && this.scheduleMeetingRequest.appointmentCitySelected != undefined) {
                            this.scheduleMeetingRequest.appointmentIDCity = this.scheduleMeetingRequest.appointmentCitySelected.id;
                        }

                        if (this.scheduleMeetingRequest.serviceSelected != null && this.scheduleMeetingRequest.serviceSelected != undefined) {
                            this.scheduleMeetingRequest.idService = this.scheduleMeetingRequest.serviceSelected.id;
                        }

                        if (this.scheduleMeetingRequest.customerSelected != null && this.scheduleMeetingRequest.customerSelected != undefined) {
                            this.scheduleMeetingRequest.idCustomer = this.scheduleMeetingRequest.customerSelected.id;
                        }
                        
                        this.scheduleMeetingRequest.dateMeetingStart = this.dateMeeting + " " + this.timeStart;
                        this.scheduleMeetingRequest.dateMeetingEnd = this.dateMeeting + " " + this.timeEnd;

                        this.listRecipients.forEach (itemRecipient => {
                            if (itemRecipient.newRegister == true) {
                                itemRecipient.id = 0
                            }
                        })

                        this.listRecipientsExternal.forEach (itemRecipient => {
                            if (itemRecipient.newRegister == true) {
                                itemRecipient.id = 0
                            }
                        })

                        this.scheduleMeetingRequest.listRecipients = this.listRecipients;
                        this.scheduleMeetingRequest.listRecipientsExternal = this.listRecipientsExternal;

                        const result = await this.$store.dispatch("scheduleModule/DateMeetingCreateUpdate", this.scheduleMeetingRequest);

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.$emit('methodConfirmToCall');
                        this.loading = false;
                        this.hideLoading();
                    }
                }
            },
        }
    };
</script>